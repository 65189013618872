@import "reset";
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --toastify-color-success: #FFC723;

  --toastify-icon-color-success: var(--toastify-color-success);

  --toastify-text-color-success: #FFC723;

  --toastify-color-progress-success: var(--toastify-color-success);
}


@font-face {
  font-family: 'Rage Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Rage Italic'), url('RAGE_1.woff') format('woff');
}

body {
  font-family: "Poppins", sans-serif;
  width: auto;
}

.font-rage-italic {
  font-family: 'Rage Italic', cursive;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

// Remove Input Time Icon
.removeInputTimeIcon::-webkit-calendar-picker-indicator {
  opacity: 0;
  position: absolute;
  width: 100%;
  left: 0;
}

// Remove Input Icon
.removeInputIcon {
  -webkit-appearance: none;
}

.removeInputIcon::-webkit-inner-spin-button,
.removeInputIcon::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

// Custom Scroll
.customScroll::-webkit-scrollbar {
  width: 3px;
}

.customScroll::-webkit-scrollbar-thumb {
  background-color: #FFC723;
  border-radius: 5px;
}

.customScroll::-webkit-scrollbar-thumb:hover {
  background-color: #75748bc2;
}

.customScroll::-webkit-scrollbar-track {
  background-color: #f3f4f6;
}

// Cancel Scroll
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
}

@media (min-width: 1024px) {
  html {
    font-size: calc(100vw / 1920 * 16);
  }
}