  .lazyLoadImageBackground {
    animation: pulse 2s infinite ease-in-out;
    opacity: 0;
    filter: blur(12px);
  }

  .lazyLoadImageBackground.lazyLoadImageLoaded {
    animation: none;
    opacity: 1;
    transition: opacity .3s;
    filter: blur(0px);
  }

  @keyframes pulse {
    0% {
      opacity: 0.8;
    }
  
    50% {
      opacity: 1;
    }
  
    100% {
      opacity: 0.8;
    }
  }

  